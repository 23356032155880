import { Controller } from "@hotwired/stimulus"
/* przykład użycia: app/views/design/uix/stimulus.html.erb */

/* Automatyczne przeładowywanie elementów na stronie
  partTarget - element strony, posiadający atrybut data-frame-id z podanym ID turbo_frame, które ma być automatycznie przeładowywane
  commonTarget - element strony, o atrybutach:
    data-controller="reload ..." oraz data-controllers-to-call="..."
    gdzie ... to inne kontrolery, z których chcemy wywołać jakieś funkcje przed/po przeładowaniu

  jeśli chcemy wywołać jakąś funkcję z innego kontrolera, to musimy nazwać ją odpowiednio beforeReload() lub afterReload()
  beforeReload() może zwracać jakiś wynik, który potem zostanie przekazany do afterReload()
*/

export default class extends Controller {
  static targets = ["part", "common"]
  static values = { loaded: Boolean }
  beforeReloadResults = []
  controllers = []

  connect() {
    console.log('connect1 reload')

    this.loadedValue = true
    if (this.hasCommonTarget) {
      this.controllers = this.commonTarget.dataset.controllersToCall.split(',')
      this.beforeReloadResults = this.controllers.map(x => null)
    }

    this.reload()
  }

  // Wykonuje akcje przed odświeżeniem
  beforeReload() {
    this.controllers.forEach((controller, index) => {
      const otherController = this.application.getControllerForElementAndIdentifier(this.commonTarget, controller)
      if (typeof otherController.beforeReload === "function") {
        this.beforeReloadResults[index] = otherController.beforeReload()
      }
    })
  }

  afterReload() {
    this.controllers.forEach((controller, index) => {
      const otherController = this.application.getControllerForElementAndIdentifier(this.commonTarget, controller)
      if (typeof otherController.afterReload === "function") {
        otherController.afterReload(this.beforeReloadResults[index])
      }
    })
  }

  // odświeżenie turbo-frame
  reload() {
    const frameId = this.partTarget.dataset.turboFrameId
    if (frameId) {
      setTimeout(() => {
        this.beforeReload()
        const turboFrame = this.partTarget.querySelector(`turbo-frame#${frameId}`)
        turboFrame.src = window.location
        turboFrame.loaded.then(() => this.afterReload())
        this.reload()
      }, "30000")
    }
  }

  disconnect() {
  }
}
