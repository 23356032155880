import { humanFilesize } from 'helpers'
import { createDirectUploadController } from 'helpers/direct_upload_controller'
export default class DropzoneObject {
  constructor(controller, options) {
    this.controller = controller
    this.options = options
    this.files = []
  }

  addFile(file) {
    if (!this.validateFileTypes(file) || this.validateFilesAmount()) return

    this.validateFileSize(file)

    this.files.push(file)
    createDirectUploadController(this.controller, file).start()
  }

  removeFile(file) {
    if (file.status === 'uploading') {
      // TODO
      this.cancelUpload(file)
    }
    this.files = this.files.filter(f => f !== file)

    this.emit("removedFile", file)
    if (this.files.length === 0) {
      return this.emit("reset", file)
    }
  }

  emit(event, detail, error) {
    detail.error = error
    const params = { bubbles: true, cancelable: true, detail: detail }
    this.controller.element.dispatchEvent(new CustomEvent(`dropzone:${event}`, params))
  }

  validateFileTypes(file) {
    if (this.options.acceptedFiles && !this.options.acceptedFiles.test(file.name)) {
      this.emit("error", file, "You can't upload files of this type.")
      return false
    }
    return true
  }

  validateFilesAmount() {
    if (this.options.maxFiles && this.files.length >= this.options.maxFiles) {
      this.emit("error", {}, "You can't upload any more files.")
      return true
    }
    return false
  }

  validateFileSize(file) {
    if (this.options.maxFilesize && file.size >= this.options.maxFilesize) {
      this.emit("error", file, `Your file is too big, (${humanFilesize(file.size)}),  (max ${humanFilesize(this.options.maxFilesize)}).`)
      return true
    }
    return false
  }
}
