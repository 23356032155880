import Dropzone from 'dropzone'
import DropzoneObject from 'helpers/dropzone_object'
import { Controller } from "@hotwired/stimulus"
import { humanFilesize } from 'helpers'

export default class extends Controller {
  static targets = ['input', 'hiddenInput', 'placeholder', 'preview', 'template', 'message',
    'progress', 'thumbnail', 'size', 'name', 'uploadprogress', 'errormessage']

  static values = {
    growWidth: String,
    growHeight: String
  }

  actions = [
    'dragenter->intum-dropzone#enter',
    'dragleave->intum-dropzone#leave',
    'drop->intum-dropzone#drop',
    'dragover@document->intum-dropzone#overDoc',
    'dragleave@document->intum-dropzone#leaveDoc',
    'drop@document->intum-dropzone#dropDoc',
    // 'click->intum-dropzone#clickInput',
  ]

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 10
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 10000000 // bytes
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles") // || ['jpg', 'png']
  }

  // TODO multiple files
  connect() {
    // WYWALIC, wylaczam auto discover bo inaczej probuje podpiac dropzone do tego elementu
    // wywalic jak juz bedziemy przechodzic na intum-dropzone, a nie na dropzone
    // nie bedzie dzialac jezeli na tej samej stronie jest intum-dropzone i dropzone bo dropzone sie nie zainicjuje
    Dropzone.autoDiscover = false

    this.counter = 0

    this.inputTarget.classList.add('hidden')
    this.inputTarget.setAttribute('multiple', 'multiple')
    this.inputTarget.addEventListener('change', this.hiddenInputChange.bind(this))

    this.bindActions()
    this.bindEvents()
    this.dropZone = createDropZone(this)
  }

  hiddenInputChange() {
    const { files } = this.inputTarget
    console.log(files)
    if (files.length) {
      for (const file of files) {
        this.dropZone.addFile(file)
      }
    }
    this.dropZone.emit("addedFiles", files)
    // setupHiddenFileInput();
  }

  bindActions() {
    // podpiecie wszystkich akcji z tablicy actions do elementu
    this.actions.forEach(element => {
      const actions = this.element.dataset.action ? this.element.dataset.action + " " : ''
      this.element.dataset.action = actions + element
    })

    this.messageTarget.dataset.action = 'click->intum-dropzone#clickInput'
    this.placeholderTarget.dataset.action = 'click->intum-dropzone#clickInput'
  }

  bindEvents() {
    this.element.addEventListener("dropzone:addedFiles", event => {
      const files = event.detail
      // tutaj dodac kolejkowanie plikow
      files.forEach(file => {
        // to dodaje do pliku previewTemplate
        this.readAndAppendPreview(file)

        this.element.classList.add("dz-started")
        file.previewTemplate.classList.add("dz-processing")
        this.placeholderTarget.classList.add('dz-preview')
        this.placeholderTarget.classList.remove('hidden')
        console.log('dropzone:addedFiles', file)
      })
    })

    this.element.addEventListener("dropzone:processing", event => {
      const file = event.detail
      console.log('dropzone:processing', file)
    })

    this.element.addEventListener("dropzone:error", event => {
      // event.preventDefault()
      const file = event.detail

      if (file.previewTemplate) {
        file.previewTemplate.classList.remove('dz-processing')
        file.previewTemplate.classList.add('dz-error')
        file.previewTemplate.classList.add("dz-complete")

        file.previewTemplate.querySelector('.dz-error-message').textContent = file.error
        file.previewTemplate.querySelector('.dz-error-message').addEventListener('click', () => {
          this.dropZone.removeFile(file)
        })
      }
    })

    this.element.addEventListener("dropzone:success", event => {
      const file = event.detail
      // TODO dodac efekt fade out do progress i success
      // success.classList.remove('hidden')
      file.previewTemplate.classList.remove('dz-processing')
      file.previewTemplate.classList.add("dz-success")
      file.previewTemplate.classList.add("dz-complete")
    })

    this.element.addEventListener("dropzone:removedFile", event => {
      const file = event.detail
      file.controller && file.controller.removeElement(file)
      file.previewTemplate && file.previewTemplate.remove()
    })

    this.element.addEventListener("dropzone:reset", () => {
      this.element.classList.remove("dz-started")

      this.placeholderTarget.classList.remove('dz-preview')
      this.placeholderTarget.classList.add('hidden')
    })
  }

  enter(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.counter++
    this.toggleClass(e.dataTransfer, true)
  }

  leave(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.counter--
    if (this.counter === 0) this.toggleClass(e.dataTransfer, false)
  }

  drop(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.counter = 0
    this.toggleClass(e.dataTransfer, false)

    const input = this.inputTarget
    if (input) {
      input.files = e.dataTransfer.files
      input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }))
      input.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }))
    }
    this.dispatch('dropped', { detail: { files: e.dataTransfer.files } })
  }

  toggleClass(dataTransfer, dragover) {
    if (dragover && this.isDroppable(dataTransfer)) {
      this.element.classList.add('st-dropzone--dragover')
    } else {
      this.element.classList.remove('st-dropzone--dragover')
    }
  }

  clickInput() {
    this.inputTarget.click()
  }

  overDoc(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.dragging = true
    this.dragin()
  }

  leaveDoc(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.dragging = false
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.dragging) this.dragout()
    }, 200)
  }

  dropDoc(e) {
    e.preventDefault()
    if (!this.isDroppable(e.dataTransfer)) return

    this.dragging = false
    this.dragout()
  }

  dragin() {
    this.element.classList.add('st-dropzone--dragin')
  }

  dragout() {
    this.element.classList.remove('st-dropzone--dragin')
  }

  readAndAppendPreview(file) {
    const template = this.templateTarget.content.cloneNode(true)
    const createdElement = template.querySelector('.dz-preview')
    const thumbnail = template.querySelector('.dz-image').querySelector('img')
    file.previewTemplate = createdElement

    this.setThumbnailDetails(file, createdElement)
    this.readFileToImage(file, thumbnail)
    // dodajemy nowo stworzony element na poczatek listy plikow
    this.element.prepend(template)

    // zwracamy utworzony element zeby mozna bylo pozniej na nim dzialac
    return createdElement
  }

  // ustawiamy src elementu img z pliku ktory zostal przeslany
  readFileToImage(file, element) {
    const reader = new FileReader()

    reader.addEventListener("load", () => {
      // convert image file to base64 string
      element.src = reader.result
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  setThumbnailDetails(file, element) {
    const details = element.querySelector('.dz-details')
    const size = details.querySelector('.dz-size').querySelector('span')
    const name = details.querySelector('.dz-filename').querySelector('span')
    const remove = element.querySelector('.dz-remove')

    size.innerHTML = humanFilesize(file.size)
    name.innerHTML = file.name

    name.classList.add('hidden')
    size.classList.add('hidden')

    element.addEventListener('mouseover', () => {
      name.classList.remove('hidden')
      size.classList.remove('hidden')
    })

    element.addEventListener('mouseout', () => {
      name.classList.add('hidden')
      size.classList.add('hidden')
    })

    remove.addEventListener('click', () => {
      this.dropZone.removeFile(file)
    })
  }

  isDroppable(dataTransfer) {
    return dataTransfer.items.length && dataTransfer.items[0].kind === 'file' && this.isAllowedByInput(dataTransfer)
  }

  isAllowedByInput(dataTransfer) {
    const input = this.inputTarget
    return !input || input.hasAttribute('multiple') || dataTransfer.items.length === 1
  }
}

function createDropZone(controller) {
  return new DropzoneObject(controller, {
    url: controller.url,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
  })
}
