import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import { useTransition } from 'stimulus-use'
/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["menu", "list", "select", "options"]
  static values = { loaded: Boolean }
  lastElement = null
  prefixId = ''
  inputs = []

  connect() {
    console.log('connect1 bulk options')

    if (this.hasMenuTarget) {
      this.prefixId = `${this.menuTarget.dataset.id}_`

      const selected = this.getSelectedFromStorage()
      this.setInputs(selected)
    }

    if (this.hasOptionsTarget) {
      useTransition(this, { element: this.optionsTarget })
    }

    this.loadedValue = true
    this.toggle()
  }

  setInputs(selected) {
    let selectedArray = []
    if (selected) {
      selectedArray = selected.split(',').map(x => `${this.prefixId}${x}`)
    }

    // ustawiamy data-action dla inputów o odpowienich id
    this.inputs = this.listTarget.querySelectorAll(`input[id^="${this.prefixId}"]`)
    if (this.inputs) {
      this.inputs.forEach(element => {
        element.setAttribute("data-action", "click->bulk-options#checkOption")
        element.setAttribute("data-bulk-options-id-param", element.id)
        if (selectedArray.includes(element.id)) {
          element.checked = true
        }
      })
    }
  }

  // wykonanie akcji (np.: masowe dodanie klienta do maila)
  action(event) {
    if (!this.checkSelected()) {
      return true
    }

    const button = document.getElementById(event.params.id)
    const data = JSON.parse(button.dataset.params)

    const selected = this.getSelected()

    let params = `${button.dataset.ids}=${selected}&`

    // dodajemy stałe parametry
    for (var key in data) {
      params += `${key}=${data[key]}&`
    }

    // jeśli ustawiamy klienta / zespół / cokolwiek po iD to bierzemy wartość z na podstawie value i valueid
    if (event.params.valueid) {
      const element = document.querySelector(`#${event.params.valueid}`)
      const value = `${event.params.value}=${element.value}`

      params += value
    }

    // usuwamy zbędne & z końca parametrów
    if (params.charAt(params.length - 1) === '&') {
      params = params.slice(0, -1)
    }

    Rails.ajax({
      url: button.dataset.url,
      type: 'post',
      data: params,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        return true
      },
      success: (partial) => {
        console.log('update success')
        if (this.listTarget.dataset.turboFrameId) {
          this.listTarget.dataset.turboFrameId.split(',').forEach(element => {
            const turboFrame = document.querySelector(`turbo-frame#${element}`)
            turboFrame.src = window.location
            turboFrame.reload()
            turboFrame.loaded.then(() => this.setInputs(selected))
          })
        } else {
          this.setInputs(selected)
        }
      },
      error: (e) => {
        console.log('error updating')
        console.log(e)
      }
    })
  }

  // zaznaczenie checkboxa
  checkOption(event) {
    const input = document.getElementById(event.params.id)
    let newValue = input.checked
    let indexes = [-1, -1]
    const inputIndex = Array.prototype.indexOf.call(this.inputs, input)

    if (event.shiftKey && this.lastElement) {
      // jeśli kliknęliśmy z shiftem i nie był to pierwszy zaznaczony element to zaznaczamy zakres
      indexes[0] = Array.prototype.indexOf.call(this.inputs, document.getElementById(this.lastElement.id))
      indexes[1] = inputIndex

      indexes = indexes.sort((a, b) => { return a - b })
      newValue = this.lastElement.value
    } else {
      // wpp zakresem jest pojedynczy checkbox
      indexes = [inputIndex, inputIndex]
    }

    Array.prototype.slice.call(this.inputs).slice(indexes[0], indexes[1] + 1).forEach(element => {
      element.checked = newValue
    })

    this.lastElement = { id: input.id, value: input.checked }
    this.selectTarget.checked = false

    this.saveSelected()
    this.toggle()
  }

  // zaznaczenie/odznaczenie wszystkich checkboxów
  selectAll(event) {
    this.inputs = this.listTarget.querySelectorAll(`input[id^="${this.prefixId}"]`)

    if (!this.inputs) {
      return true
    }

    if (this.selectTarget.checked) {
      this.inputs.forEach(element => {
        element.checked = true
      })
    } else {
      this.inputs.forEach(element => {
        element.checked = false
      })
    }

    this.lastElement = null
    this.saveSelected()
    this.toggle()
  }

  // sprawdzenie czy jakikolwiek checkbox jest zaznaczony
  checkSelected() {
    return this.listTarget.querySelectorAll(`input[id^="${this.prefixId}"]:checked`).length > 0
  }

  // pobranie listy id zaznaczonych elementów
  getSelected() {
    const list = this.listTarget.querySelectorAll(`input[id^="${this.prefixId}"]:checked`)

    let ids = ""

    list.forEach(element => {
      ids += `${element.id.split(this.prefixId)[1]},`
    })

    // usuwamy zbędny przecinek z końca parametrów
    if (ids.charAt(ids.length - 1) === ',') {
      ids = ids.slice(0, -1)
    }

    return ids
  }

  getSelectedFromStorage() {
    const selected = sessionStorage.getItem(`bulk_options_${this.prefixId}list`)
    if (sessionStorage.getItem(`bulk_options_${this.prefixId}page`) === window.location.href && selected) {
      return selected
    } else {
      this.saveSelected('')
      return ''
    }
  }

  saveSelected(selection = this.getSelected()) {
    sessionStorage.setItem(`bulk_options_${this.prefixId}page`, window.location.href)
    sessionStorage.setItem(`bulk_options_${this.prefixId}list`, selection)
  }

  toggle() {
    const checked = this.checkSelected()
    const hidden = this.optionsTarget.classList.contains('hidden')

    if (checked && hidden) {
      console.log('toggle1')
      this.toggleTransition()
    }

    if (!checked && !hidden) {
      console.log('toggle1')
      this.toggleTransition()
    }
  }

  /*
    funkcje wywoływane przed i po automatycznym odświeżeniu listy (patrz reload_controller.js)
    ------ START ------
  */
  beforeReload() {
    this.saveSelected()
  }

  afterReload(_) {
    this.setInputs(this.getSelectedFromStorage())
  }
  /*
    funkcje wywoływane przed i po automatycznym odświeżeniu listy (patrz reload_controller.js)
    ------ END ------
  */

  disconnect() {
  }
}
