import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

// Connects to data-controller="markdown-editor"
export default class extends Controller {
  static targets = ["input", "editor", "preview", "show", "title"]
  connect() {
    this.previewTarget.classList.add('hidden')
  }

  insert(type) {
    const selectionStart = this.inputTarget.selectionStart
    const selectionEnd = this.inputTarget.selectionEnd

    const v = this.inputTarget.value
    const textBefore = v.substring(0, this.inputTarget.selectionStart)
    const textSelected = v.substring(this.inputTarget.selectionStart, this.inputTarget.selectionEnd)
    const textAfter = v.substring(this.inputTarget.selectionEnd)

    var changedText
    var lines = textSelected.split("\n")
    const startsEndsWithType = (line) => line.startsWith(type) && line.endsWith(type)

    if (lines.every(startsEndsWithType)) {
      changedText = lines.map(function (line) { return line.substring(type.length, line.length - type.length) }).join("\n")
      this.inputTarget.value = textBefore + changedText + textAfter
      this.inputTarget.selectionStart = selectionStart
      this.inputTarget.selectionEnd = selectionEnd - 2 * type.length * lines.length
    } else {
      changedText = lines.map(function (line) { return type + line + type }).join("\n")
      this.inputTarget.value = textBefore + changedText + textAfter
      this.inputTarget.selectionStart = selectionStart
      this.inputTarget.selectionEnd = selectionEnd + 2 * type.length * lines.length
    }

    this.inputTarget.focus()
  }

  startOfLineIndex(v, selectionStart) {
    for (var i = selectionStart - 1; i >= 0; i--) {
      if (v[i] === '\n') { return i + 1 }
    }
    return 0
  }

  endOfLineIndex(v, selectionEnd) {
    for (var i = selectionEnd; i <= v.length; i++) {
      if (v[i] === '\n') { return i }
    }
    return v.length
  }

  // code -1 -> tylko usuwanie
  // code 0 -> usuwanie i dodawanie
  // code 1 -> tylko dodawanie
  insertAtStart(type, code = 0) {
    const selectionStart = this.inputTarget.selectionStart
    const selectionEnd = this.inputTarget.selectionEnd
    const v = this.inputTarget.value

    var firstElementIndex = this.startOfLineIndex(v, selectionStart)
    var LastElementIndex = this.endOfLineIndex(v, selectionEnd)

    const textBefore = v.substring(0, firstElementIndex)
    const textModified = v.substring(firstElementIndex, LastElementIndex)
    const textAfter = v.substring(LastElementIndex)

    var changedText
    var lines = textModified.split("\n")
    const startsWithType = (line) => line.startsWith(type)

    if (code !== 1 && lines.every(startsWithType)) {
      changedText = lines.map(function (line) { return line.slice(type.length) }).join("\n")
      this.inputTarget.value = textBefore + changedText + textAfter
      this.inputTarget.selectionStart = Math.max(selectionStart - type.length, firstElementIndex)
      this.inputTarget.selectionEnd = selectionEnd - type.length * lines.length
    } else if (code !== -1) {
      changedText = lines.map(function (line) { return type + line }).join("\n")
      this.inputTarget.value = textBefore + changedText + textAfter
      this.inputTarget.selectionStart = selectionStart + type.length
      this.inputTarget.selectionEnd = selectionEnd + type.length * lines.length
    }

    this.inputTarget.focus()
  }

  insertNumberAtStart(startFrom = 1) {
    const selectionStart = this.inputTarget.selectionStart
    const selectionEnd = this.inputTarget.selectionEnd

    const v = this.inputTarget.value

    var firstElementIndex = this.startOfLineIndex(v, selectionStart)
    var LastElementIndex = this.endOfLineIndex(v, selectionEnd)

    const textBefore = v.substring(0, firstElementIndex)
    const textModified = v.substring(firstElementIndex, LastElementIndex)
    const textAfter = v.substring(LastElementIndex)

    var changedText
    var lines = textModified.split("\n")
    const startsWithNumber = (line) => line.match(/^(\d+\. )/)
    var lengthChange = 0

    if (lines.every(startsWithNumber)) {
      changedText = lines.map(function (line) {
        var typeLength = line.match(/^(\d+\. )/)[0].length
        lengthChange += typeLength
        return line.slice(typeLength)
      }).join("\n")
      this.inputTarget.value = textBefore + changedText + textAfter

      this.inputTarget.selectionStart = Math.max(selectionStart - startFrom.toString().length - 2, firstElementIndex) // "x. ".length
      this.inputTarget.selectionEnd = selectionEnd - lengthChange
    } else {
      var pointNumber = startFrom - 1
      changedText = lines.map(function (line) {
        pointNumber++
        var addedPrefix = pointNumber.toString() + ". "
        lengthChange += addedPrefix.length
        return (addedPrefix + line)
      }).join("\n")

      this.inputTarget.value = textBefore + changedText + textAfter
      this.inputTarget.selectionStart = selectionStart + startFrom.toString().length + 2 // "x. ".length
      this.inputTarget.selectionEnd = selectionEnd + lengthChange
    }

    this.inputTarget.focus()
  }

  insertAtEnd(type) {
    const selectionStart = this.inputTarget.selectionStart
    const selectionEnd = this.inputTarget.selectionEnd
    const v = this.inputTarget.value
    const indexBefore = 0
    const indexAfter = v.length + this.inputTarget.selectionEnd
    const textSelected = v.substring(indexBefore, indexAfter)
    if (textSelected.indexOf(type) === v.length - type.length) {
      this.inputTarget.selectionStart = selectionStart - type.length
      this.inputTarget.selectionEnd = selectionEnd - type.length
    } else {
      this.inputTarget.value = textSelected + " " + type
      this.inputTarget.selectionStart = v.length + type.length + 1
      this.inputTarget.selectionEnd = v.length + type.length + 1
    }

    this.inputTarget.focus()
  }

  insertAtCursor(type) {
    const selectionStart = this.inputTarget.selectionStart

    const v = this.inputTarget.value
    const textBefore = v.substring(0, this.inputTarget.selectionStart)
    // const textSelected = v.substring(this.inputTarget.selectionStart, this.inputTarget.selectionEnd)
    const textAfter = v.substring(this.inputTarget.selectionEnd, v.length)

    this.inputTarget.value = textBefore + type + textAfter
    this.inputTarget.selectionStart = selectionStart + type.length

    this.inputTarget.selectionEnd = this.inputTarget.selectionStart
    this.inputTarget.focus()
  }

  updateText(event) {
    function updateNextLines(startingNumber, v, selectionEnd) {
      var line = ""
      var result = {
        textModified: "",
        lastModified: 0
      }
      var textModified = ""
      var currentNumber = startingNumber

      for (var i = selectionEnd + 1; i <= v.length; i++) {
        if (v[i] === '\n' || i === v.length) {
          if (line.match(/^(\d+)(\. .*)$/)) {
            const oldNumber = parseInt(line.match(/^(\d+)(\. .*)$/)[0])
            const oldLength = oldNumber.toString().length

            line = line.slice(oldLength)
            line = currentNumber + line
            currentNumber++
          }
          textModified += (line + '\n')

          if (line.length === 0 || i === v.length) {
            result.textModified = textModified
            result.lastModified = i

            return result
          }
          line = ""
        } else {
          line += v[i]
        }
      }
      return null
    }
    if (event.code === 'Enter') {
      let lastLine = this.inputTarget.value.substring(0, this.inputTarget.selectionStart - 1)
      lastLine = lastLine.substring(lastLine.lastIndexOf('\n') + 1, lastLine.length)

      const matchNumbered = lastLine.match(/^(\d+)(\. .+)$/)
      const matchList = lastLine.match(/^-( .+)$/)

      if (matchNumbered && matchNumbered.length > 2) {
        const number = parseInt(matchNumbered[1]) + 1
        this.insertAtStart(number + ". ")

        const v = this.inputTarget.value
        const selectionStart = this.inputTarget.selectionStart
        const selectionEnd = this.inputTarget.selectionEnd

        const textBefore = v.substring(0, selectionStart + 1)
        const update = updateNextLines(number + 1, v, selectionEnd)
        const textModified = update.textModified
        const textAfter = v.substring(update.lastModified + 1, v.length)

        this.inputTarget.value = textBefore + textModified + textAfter
        this.inputTarget.selectionStart = selectionStart
        this.inputTarget.selectionEnd = selectionEnd
      } else if (matchList) {
        this.insertAtStart("- ")
      }
    }
    this.inputTarget.focus()
  }

  insertTab(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      var code = event.shiftKey ? -1 : 1 // -1 -> usuwanie tabów, 1 -> dodawanie
      this.insertAtStart('\t', code)
    }
  }

  insertHeader() {
    this.insertAtStart('### ')
  }

  insertBold() {
    this.insert('**')
  }

  insertItalic() {
    this.insert('_')
  }

  insertStrikethrough() {
    this.insert('~~')
  }

  insertCode() {
    this.insert('`')
  }

  insertList() {
    this.insertAtStart('- ')
  }

  insertNumberedList() {
    this.insertNumberAtStart()
  }

  insertImage() {
    const fileField = document.querySelector('#entry_entry_attachment')
    fileField.click()
    fileField.addEventListener('change', this.uploadFile.bind(this), false)
    // replacing filefield to remove event listeners
    fileField.replaceWith(fileField.cloneNode())
  }

  insertAttachmentImage(element) {
    const { alt, filename, title } = element.detail
    this.insertAtCursor(this.imgTemplate(alt, filename, title))
    this.element.scrollIntoView()
  }

  moveToTitle(event) {
    // przejdź do tytułu tylko z pierwszej linijki treści
    if (event.keyCode === 38 && this.inputTarget.selectionEnd === 0) {
      this.titleTarget.focus()
    }
  }

  moveToContent(event) {
    if (event.keyCode === 40 || event.code === 'Enter') {
      event.preventDefault()
      this.inputTarget.focus()
    }
  }

  imgTemplate(alt, filename, title) {
    if (alt.length === 0) {
      alt = 'opis'
    }
    if (title.length === 0) {
      title = 'tytul'
    }
    return `![${alt}](${filename} "${title}")`
  }

  uploadFile(event) {
    const fileField = document.querySelector('#entry_entry_attachment')
    const imagesField = document.querySelector('#editor_images')
    const uploadPath = fileField.dataset.directUploadUrl
    // let createAttachmentPath = fileField.dataset.createAttachmentUrl
    const fileList = event.target.files
    console.log(fileList)
    const file = fileList[fileList.length - 1]
    const upload = new DirectUpload(file, uploadPath)

    upload.create((error, blob) => {
      if (error) {
        console.log('upload error', error)
      } else {
        console.log('upload success')
        this.insertAtCursor(this.imgTemplate('opis', `${blob.filename}`, 'tytul'))
        imagesField.setAttribute("value", `${imagesField.value ? imagesField.value + ',' : ''}${blob.signed_id}`)
      }
    })
  }

  // toggleWrite() {
  //   this.editorTarget.classList.remove("hidden")
  //   this.previewTarget.classList.add("hidden")
  // }

  // togglePreview() {
  //   this.editorTarget.classList.add("hidden")
  //   this.previewTarget.classList.remove("hidden")
  // }
}
