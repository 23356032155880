import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js"

export default class extends Controller {
  static targets= ["canvas"]

  connect() {
    const sprintChart = this.canvasTarget

    const tailwindColors = {
      blue: {
        default: "rgba(59, 130, 246, 1)",
        half: "rgba(59, 130, 246, 0.5)",
        quarter: "rgba(59, 130, 246, 0.25)",
        zero: "rgba(59, 130, 246, 0)"
      },
      teal: {
        default: "rgba(20, 184, 166, 0.6)",
        half: "rgba(20, 184, 166, 0.3)",
        quarter: "rgba(20, 184, 166, 0.15)",
        zero: "rgba(20, 184, 166, 0)"
      }
    }

    const labelValue = this.canvasTarget.dataset.dates.split(',')

    this.chart = new Chart(sprintChart, {
      type: 'line',
      data: {
        labels: labelValue,
        datasets: [{
          tension: 0,
          data: this.canvasTarget.dataset.values.split(','),
          fill: true,
          backgroundColor: (context) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, this.canvasTarget.height / 1.2)
            gradient.addColorStop(0, tailwindColors.blue.half)
            gradient.addColorStop(0.35, tailwindColors.blue.quarter)
            gradient.addColorStop(1, tailwindColors.blue.zero)
            return gradient
          },
          hoverBackgroundColor: "red",
          borderWidth: 2,
          borderColor: tailwindColors.blue.default
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          mode: 'point'
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(200, 200, 200, 1)',
              lineWidth: 0
            }
          }],
          yAxes: [{
            ticks: {
              precision: 0,
              beginAtZero: true,
            },
            gridLines: {
              color: 'rgba(200, 200, 200, 1)',
              lineWidth: 1
            },
          }],
          y: {
            beginAtZero: true
          }
        },
        legend: {
          display: false
        },
      }
    })
  }
}
