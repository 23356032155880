import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments"
export default class extends Controller {
  static outlets = ["dropdown-photo"]
  static targets = ["dropdown", "button"]

  mainDropdownPhotoContainer = this.dropdownTarget.firstElementChild

  connect() {
    this.dropdownTarget.classList.add('hidden')
    this.addEventListenersToLinks()
  }

  toggleDropdown(event) {
    event.preventDefault()

    this.dropdownTarget.classList.remove('hidden')

    const dropdownPhoto = this.findDropdownElement()
    const mainElement = dropdownPhoto.element

    // firstChild czyli element ktory widzimy na stronie zanim otworzymy dropdown w normalnych warunkach
    // ukrywamy go bo chcemy zeby po otwarciu dropdowna byl widoczny tylko element z dropdowna
    const firstChild = mainElement.firstElementChild.firstElementChild
    firstChild.classList.add('hidden')

    dropdownPhoto.open()
  }

  // znajdujemy konkretny dropdownPhoto, ktory jest podpiety pod nasz dropdown do komentarzy
  findDropdownElement() {
    return this.dropdownPhotoOutlets.find(element => {
      return element.element.parentElement === this.dropdownTarget
    })
  }

  // z racji, ze konstrukcja tego dropdownu jest taka, ze akcje sa wywolywane przez klikniecie w link
  // to musimy sluchac eventow na linkach
  findLinksFromDropdown() {
    return this.dropdownTarget.querySelectorAll('li > a')
  }

  // do kazdego linka dodajemy listener, ktory tworzy i wysyla button odpowiedzialny za tworzenie komentarza i przypisanie go do usera
  addEventListenersToLinks() {
    const links = this.findLinksFromDropdown()
    links.forEach(link => {
      link.addEventListener('click', this.submitButtonAndCloseDropdown.bind(this))
    })
  }

  submitButtonAndCloseDropdown(e) {
    const dropdownPhoto = this.findDropdownElement()
    dropdownPhoto.toggle()
    this.createAndSubmitNewButton(e.target.dataset.value)

    // to jest workaround, poniewaz probowalem dolaczyc drugi outlet ktory odpowiada za dropdown w ktorym otwierany jest
    // dropdownPhoto, ale cos chyba z tym API jest jeszcze nie tak bo nie wylapuje tego kontrolera prawidlowo, wiec to jest
    // po to zeby zamknac dropdown po tym jak komentarz zostanie zapisany
    this.element.click()
  }

  createAndSubmitNewButton(value) {
    const button = document.createElement('button')
    const dropdownPhoto = this.findDropdownElement()
    button.type = 'submit'
    button.name = 'comment_and_assign'
    button.classList.add('hidden')
    button.value = value

    dropdownPhoto.element.appendChild(button)
    button.click()
  }
}
